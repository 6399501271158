<template>
  <div>
    
    <router-view/>
  </div>
</template>
<script>
export default {
  methods: {
    showVanta() {
       
    }
  },
  created() {
    this.showVanta()
  }
}
</script>

<style>

</style>
